<template>
    <div class="contents">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main">
                        <h4 class="text-capitalize breadcrumb-title">Tagihan</h4>
                    </div>
                    <div v-if="isExpired()">
                        <div class="alert alert-danger" role="alert">
                            Akun Anda Telah Expired !
                        </div>
                        <br>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        components: {

        },
        data() {
            return {
                isLoading: true,
                myPackageLoading: true,
                myPackage: {
                    id: "",
                    name: "",
                    pesan: "0",
                    device: "0",
                    customer_service: "0",
                    campaign: "0",
                    order_online: "0",
                    link_rotator: "0",
                    chat_widget: "0",
                    expired_at: "",
                    webhook_media_quota:0

                },
                withdrawableBalance: 0,
                user_balance: 0,
                packageList: [],
                extendURL: "",
                syncText: "Sinkronisasi dalam proses..."
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
            this.$root.$off("refresh_data", this.fnRefreshData);
        },
        async mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);

            this.fnRefreshData = (param) => {
                if(param == "user") this.fetchUser()
            }
            this.$root.$on("refresh_data", this.fnRefreshData);

            await this.fetchUser()
            await this.fetchPacakge()
            if(this.$store.state.auth.user.level == 'user-agency'){
                await this.fetchAgencySetting()
            }

            this.isLoading = false
        },
        methods: {
            async fetchUser() {
                try {
                    let user_data = await this.$store.dispatch('user_detail')
                    this.user_balance = user_data.data.data.user.balance
                    this.withdrawableBalance = user_data.data.data.user.withdrawable_balance
                    this.fetchMyPacakge(user_data.data.data.package_data)
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                    this.$router.push("/billing")
                }
            },
            async fetchMyPacakge(p){
                this.myPackageLoading = true
                try{
                    this.myPackage.id = p.id
                    this.myPackage.name = p.package_data.name
                    this.myPackage.pesan = p.message_quota == "-1" ? "Unlimited" : p.message_quota
                    this.myPackage.device = p.max_device == "-1" ? "Unlimited" : p.max_device
                    this.myPackage.customer_service = p.max_cs == "-1" ? "Unlimited" : p.max_cs
                    this.myPackage.campaign = p.max_campaign == "-1" ? "Unlimited" : p.max_campaign
                    this.myPackage.order_online = p.max_order_online == "-1" ? "Unlimited" : p.max_order_online
                    this.myPackage.link_rotator = p.max_link_rotator == "-1" ? "Unlimited" : p.max_link_rotator
                    this.myPackage.chat_widget = p.max_chat_widget == "-1" ? "Unlimited" : p.max_chat_widget
                    this.myPackage.expired_at = moment.unix(p.expired_at / 1000).format('DD MMMM YYYY')
                    this.myPackage.webhook_media_quota = parseInt(p.webhook_media_quota)/1000000
                }catch(err){
                    console.log(err)
                }
                this.myPackageLoading = false
            },
            async fetchPacakge(){
                this.myPackageLoading = true
                try{
                    let packageList = await this.$store.dispatch('package_list')
                    let pkg = packageList.data.data.packages
                    let p = []

                    for(let i = 0; i < pkg.length; i++){
                        if(this.$store.state.auth.user.level == 'user-agency' && pkg[i].is_addon == 0){
                            continue
                        }

                        p.push(pkg[i])
                    }

                    for (let i = 0; i < p.length; i++) {
                        p[i].pesan = p[i].message_quota == "-1" ? "Unlimited" : p[i].message_quota
                        p[i].device = p[i].max_device == "-1" ? "Unlimited" : p[i].max_device
                        p[i].customer_service = p[i].max_cs == "-1" ? "Unlimited" : p[i].max_cs
                        p[i].campaign = p[i].max_campaign == "-1" ? "Unlimited" : p[i].max_campaign
                        p[i].order_online = p[i].max_order_online == "-1" ? "Unlimited" : p[i].max_order_online
                        p[i].link_rotator = p[i].max_link_rotator == "-1" ? "Unlimited" : p[i].max_link_rotator
                        p[i].chat_widget = p[i].max_chat_widget == "-1" ? "Unlimited" : p[i].max_chat_widget
                        p[i].webhook_media_quota = parseInt(p[i].webhook_media_quota)/1000000
                        if(p[i].id == this.myPackage.id){
                            p[i].isMyPackage = true
                        }else{
                            p[i].isMyPackage = false 
                        }
                    }

                    this.packageList = p
                }catch(err){
                    console.log(err)
                }
                this.myPackageLoading = false
            },
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            formatRupiah(numb){
                const format = numb.toString().split('').reverse().join('');
                const convert = format.match(/\d{1,3}/g);
                const rupiah = 'Rp ' + convert.join('.').split('').reverse().join('')
                return rupiah
            },
            matchPackage(a, b){
                if(a == b){
                    return true 
                }else{
                    return false
                }
            },
            isExpired(){
                let now = moment().format('YYYY-MM-DD HH:mm:ss');
                if(this.$store.state.auth.user.expired_at < now){
                    return true
                }else{
                    return false
                }
            },
            async fetchAgencySetting(){
                let setting = await this.$store.dispatch('agency_setting_user')
                this.extendURL = setting.data.data.setting.extend_url
            },
            async sync(){
                try{
                    await this.$store.dispatch('package_sync', {})
                    this.syncText = "Sinkronisasi berhasil, silakan refresh halaman ini"
                }catch(err){
                    this.syncText = "Sinkronisasi gagal, pastikan email di v2 dan v3 sama atau kontak admin"
                    this.$toast.error(err.response.data.message)
                }
            }
        },
    };
</script>

<style scoped>
    .nav-link{
        cursor: pointer !important;
    }

    .pricing__tag_new{
        font-size: 13px;
        line-height: 1.5384615385;
        font-weight: 500;
        padding: 7px 22px;
        color: black;
    }

    .blackBorder{
        border: 3px solid black;
    }
</style>
